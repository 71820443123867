globalThis["__sentryRewritesTunnelPath__"] = undefined;
globalThis["SENTRY_RELEASE"] = {
    "id": "8045df1288a95c95effd84a288e109139332af6c"
};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
import * as Sentry from "@sentry/nextjs";
import { getClientEnvConfig, getAppVersion, sharedFrontendSentryErrorsToIgnore } from "lib-fullstack/client_env";
var envConfig = getClientEnvConfig();
var release = getAppVersion();
Sentry.init({
    dsn: "https://c740e80f5939498e8ef097ef42b81a07@o1093573.ingest.sentry.io/6112898",
    tracesSampleRate: 1.0,
    ignoreErrors: sharedFrontendSentryErrorsToIgnore,
    environment: envConfig.envName,
    release: release
});
